<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="home" href="/sale/main/home" @click="navigation('/sale/main/home')">
          <ion-icon :icon="homeOutline" />
          <ion-label>{{ $t('tabs.home') }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="order" href="/sale/order" @click="navigation('/sale/order')">
          <ion-icon :icon="bagHandleOutline" />
          <ion-label>{{ $t('tabs.orders') }}</ion-label>
        </ion-tab-button>
        <ion-tab-button
          tab="cart"
          href="/sale/cart"
          class="position-relative"
          @click="navigation('/sale/cart')"
        >
          <ion-icon :icon="cartOutline" />
          <div class="cart-badge" v-if="cartBadgeNumber">{{ cartBadgeNumber }}</div>
          <ion-label>{{ $t('tabs.cart') }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="account" href="/sale/account" @click="navigation('/sale/account')">
          <ion-icon :icon="personOutline" />
          <ion-label>{{ $t('tabs.account') }}</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { ACTIONS as ACTIONS_CART } from '@/modules/sale/store/cart/actions';
import {
  bagHandleOutline,
  cartOutline,
  homeOutline,
  personOutline,
  searchOutline,
  settingsOutline
} from 'ionicons/icons';
import { defineComponent, ref } from 'vue';

import { getCartBadge, setBadgeNumber } from '@/modules/shared/utils/badge';
import { computed, inject, onMounted } from 'vue';
import { createNamespacedHelpers, useStore } from 'vuex';

const { mapGetters: mapGettersCustomer } = createNamespacedHelpers('sale/customer');
const { mapActions: mapActionsCart } = createNamespacedHelpers('sale/cart');

export default defineComponent({
  name: 'tabs',
  setup() {
    const storage = inject('$storage');
    const store = useStore();
    const selectedCompany = ref(null);
    const user = ref(null);
    const ionRouter = inject('navManager');

    const cartBadgeNumber = computed(() => getCartBadge(store));

    onMounted(async () => {
      selectedCompany.value = await storage.getSelectedCompany();
      user.value = await storage.getUser();
      await setBadgeNumber(store, selectedCompany.value.id, user.value.tenant.id);
    });

    return {
      selectedCompany,
      user,

      homeOutline,
      searchOutline,
      settingsOutline,
      personOutline,
      cartOutline,
      bagHandleOutline,
      ionRouter,
      cartBadgeNumber
    };
  },

  computed: {
    ...mapGettersCustomer(['selectedCustomer'])
  },
  methods: {
    ...mapActionsCart([ACTIONS_CART.GET_CART_ITEMS]),
    navigation(params) {
      this.ionRouter.navigate({
        routerDirection: 'none',
        routerLink: params
      });
    }
  }
});
</script>

<style scoped>
.cart-badge {
  position: absolute;
  background-color: var(--ion-color-primary);
  color: white;
  top: 2px;
  right: 16%;
  width: 20px;
  height: 16px;
  border-radius: 5px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
